import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import typography from "./typography";
import props from "./props";
import globalColors from "./global-colors";

const linkHover = "#038FAD";
const linkActive = "#02738C";

export default responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      mode: "dark",
      primary: {
        main: "#03A9CE",
        hover: "#2579AB",
        selected: "#2377A9",
        disabled: "#5B5B5F",
      },
      secondary: {
        main: "#FFFFFF",
        hover: "#C8C8C8",
        selected: "#B5B5B5",
        property: "border",
      },
      error: {
        // main: "#DC6A6A",
        main: "#F04755",
        border: "#FF6370",
      },
      success: {
        // main: "#5FA462",
        main: "#37AF6B",
      },
      info: {
        main: "#C1D1DB",
      },
      errorSnackbar: {
        background: "rgba(255, 99, 112, 0.1)",
        border: "#FF6370",
      },
      successSnackbar: {
        background: "rgba(110, 204, 151, 0.1)",
        border: "#6ECC97",
      },
      infoSnackbar: {
        background: "rgba(193, 209, 219, 0.1)",
        border: "#C1D1DB",
      },
      warningSnackbar: {
        background: "rgba(237, 108, 2, 0.1)",
        border: "#ED6C02",
      },
      text: {
        primary: "#FFFFFF",
        // secondary: "#ededed",
        secondary: "#9BA2BA",
        disabled: "#8D8D91",
      },
      background: {
        paper: "#052435",
        default: "#0D323E",
        main:
          "linear-gradient(98.09deg, rgba(1, 243, 247, 0.1) -4.98%, rgba(13, 122, 252, 0.1) 98.56%)",
        secondBackground: "#052435",
        footer: "#FAFAFA",
      },
      input: {
        main: {
          background:
            "linear-gradient(360deg, rgba(3, 116, 138, 0.14) 0%, rgba(99, 179, 194, 0) 115.62%)",
          border: "#2A5369",
          color: "#FFFFFF",
        },
        hover: {
          background:
            "linear-gradient(360deg, rgba(3, 116, 138, 0.14) 0%, rgba(99, 179, 194, 0) 115.62%)",
          border: "#244D63",
        },
        selected: {
          background:
            "linear-gradient(360deg, rgba(3, 116, 138, 0.14) 0%, rgba(99, 179, 194, 0) 115.62%)",
          border: "#03A9CE",
        },
        disabled: {
          background: "#052435",
          border: "#2A5369",
          color: "#436A7A",
        },
      },
      divider: "rgba(255, 255, 255, 0.15)",
      backgroundLines: "#ffffff",
      contentBackground: "#052435",
      modal: {
        backdrop: "rgba(3, 25, 37, 0.8)",
        background: "#044A62",
        iconBackground: "#29293A",
        icon: "#03A9CE",
        border: "#104452",
      },
      progress: {
        background: "#333248",
        color: "#01F3F7",
      },
      tabs: {
        background: "#161528",
        selectedBackground: "#29293A",
        divider: "#25252A",
      },
      tab: {
        border: "rgba(255, 255, 255, 0.3)",
        borderSelected: "#03A9CE",
        borderSelectedHover: "#038FAD",
        text: "#fff",
        textHover: "#E5E5E5",
        textSelected: "#03A9CE",
        textSelectedHover: "#038FAD",
      },
      slider: {
        left: "#5A5B60",
        right: "#3895CD",
        bar: "linear-gradient(270deg, #052435 0%, rgba(5, 36, 53, 0) 95.58%)",
        leftTrack: "#03A9CE",
        rightTrack: "#3895CD",
        thumb: {
          color: "#052435",
          border: "#044A62",
          colorValue: "#04465D",
          borderValue: "#03A9CE",
        },
        track:
          "linear-gradient(270deg, #03A9CE 1.74%, rgba(3, 169, 206, 0.29) 39.91%, rgba(3, 169, 206, 0.09) 66.57%, rgba(3, 169, 206, 0) 96.3%)",
      },
      menu: {
        hover: "rgba(255, 255, 255, 0.1)",
      },
      footer: {
        background: "#0D323E",
        text: "#FFFFFF",
        link: "#fff",
        logo: "#fff",
        stopOpacity: [0.06, 0.13, 0.45],
        stopOpacityMobile: [0, 0.09, 0.48],
        gradient: "#fff",
      },
      header: {
        // background: "#052435",//linear-gradient(90.51deg, rgba(5, 36, 53, 0) 0%, #052435 50.44%)
        background:
          "linear-gradient(90.51deg, rgba(5, 36, 53, 0) 0%, #052435 50.44%)",
        secondBackground: "#0D323E",
        divider: "rgba(255, 255, 255, 0.15)",
      },
      mobileMenu: {
        selected: "#0D7AFC",
        background: "#052435",
      },
      avatarBackground: "#314A5D",
      icon: {
        primary: "#9BA2BA",
        secondary: "#FFFFFF33",
      },
      switch: {
        track: "#000000",
        thumb: "#9BA2BA",
        border: "#21212A",
        thumbChecked: "#3589BB",
        thumbBorder: "#C7C7C7",
        thumbBorderChecked: "#2A7EB0",
      },
      rowBackground: {
        main: "#052435",
        hover: "#29292E",
        active: "#2C2C30",
      },
      rowAlmostBackground: {
        main: "#052435",
        hover: "#38383E",
        active: "#47474D",
      },
      dropdown: {
        selectedBg: "#2F2F35",
        itemBg: "#2F2F35",
        border: "#5B5B5F",
      },
      chart: {
        grid: "rgba(42, 83, 105, 1)",
        gridTransparent: "rgba(42, 83, 105, 0)",
        volumeBorder: "#fff",
        volumeFill: "#BEDCFF",
      },
      headerMenu: {
        icon: "#47474D",
        iconHover: "#FFFFFF",
        shadow: "0px 0px 8px rgba(255, 255, 255, 0.5)",
        shadowHover: "0px 0px 8px rgba(19, 103, 153, 0.5)",
      },
      lab: {
        border: "transparent",
      },
      table: {
        background: "#052435",
        border: "#17536C",
        extendedRow: "#02151F",
        extended: "#02151F",
        rowShadow: "transparent",
      },
      picker: {
        background: "linear-gradient(273.74deg, #03748A 3.07%, #63B3C2 96.93%)",
        border: "#1D7E98",
        text: "#FFFFFF",
        backgroundHover: "#062A3D",
        backgroundSelectedHover:
          "linear-gradient(273.74deg, #0489A3 3.07%, #74D1E3 96.93%)",
      },
      dataHeader: {
        icon: "#9BA2BA",
        background: "rgba(255, 255, 255, 0.25)",
        text: "#fff",
      },
      overviewPage: {
        backgroundInterval: "rgba(255, 255, 255, 0.08)",
        backgroundIntervalBorder: "#1D7E98",
      },
      filter: {
        background: "#031925",
        textFieldBackground: "#303D49",
      },
      terminal: {
        background: "#031925",
        border: "#104452",
      },
      arbitrage: {
        itemBackground:
          "linear-gradient(273.74deg, rgba(3, 116, 138, 0.16) 3.07%, rgba(99, 179, 194, 0.16) 96.93%)",
        itemBorder: "#17536C",
      },
      viewAllButton: {
        background:
          "linear-gradient(273.74deg, rgba(3, 116, 138, 0.16) 3.07%, rgba(99, 179, 194, 0.16) 96.93%)",
        shadow: "0px 30px 32px -11px rgba(43, 137, 157, 0.08)",
        text: "#fff",
      },
      extendedItem: {
        background: "#021017",
      },
      badge: {
        background:
          "linear-gradient(273.74deg, rgba(3, 116, 138, 0.16) 3.07%, rgba(99, 179, 194, 0.16) 96.93%)",
        border: "#104452",
        text: "#fff",
        shadow: "none",
      },
      badgeCount: {
        background: "rgba(4, 74, 98, 0.3)",
        border: "rgba(3, 116, 138, 1)",
        shadow: "0px 4px 40px rgba(255, 255, 255, 0.04)",
      },
      paper: {
        background: "#052435",
        border: "#104452",
        shadow: "0px 4px 40px rgba(255, 255, 255, 0.04)",
        divider: "#104452",
      },
      chartTooltip: {
        background:
          "linear-gradient(273.74deg, rgba(3, 116, 138, 0.16) 3.07%, rgba(99, 179, 194, 0.16) 96.93%)",
        border: "#104452",
        shadow: "",
        divider:
          "linear-gradient(90deg, rgba(16, 68, 82, 0) 0%, #104452 46.73%, rgba(16, 68, 82, 0) 100%)",
      },
      navItem: {
        backgroundHover:
          "linear-gradient(273.74deg, rgba(4, 137, 163, 0.24) 3.07%, rgba(116, 209, 227, 0.24) 96.93%)",
        backgroundSelected:
          "linear-gradient(273.74deg, #03748A 3.07%, #63B3C2 96.93%)",
        backgroundSelectedHover:
          "linear-gradient(273.74deg, #0489A3 3.07%, #74D1E3 96.93%)",
      },
      button: {
        primary: {
          background:
            "linear-gradient(273.74deg, #03748A 3.07%, #63B3C2 96.93%)",
          backgroundHover:
            "linear-gradient(273.74deg, #0489A3 3.07%, #74D1E3 96.93%)",
          border: "#104452",
          text: "#fff",
        },
        secondary: {
          background:
            "linear-gradient(273.74deg, rgba(3, 116, 138, 0.16) 3.07%, rgba(99, 179, 194, 0.16) 96.93%)",
          backgroundHover:
            "linear-gradient(273.74deg, rgba(4, 137, 163, 0.24) 3.07%, rgba(116, 209, 227, 0.24) 96.93%)",
          border: "#104452",
          text: "#fff",
        },
        outlined: {
          background: "#052435",
          backgroundHover:
            "linear-gradient(273.74deg, rgba(4, 137, 163, 0.24) 3.07%, rgba(116, 209, 227, 0.24) 96.93%)",
          border: "#104452",
          text: "#fff",
        },
      },
      stickyHeader: {
        background:
          "linear-gradient(180deg, #052435 0%, rgba(5, 36, 53, 0.92) 63.54%, rgba(5, 36, 53, 0.42) 100%)",
      },
      pagination: {
        backgroundHover:
          "linear-gradient(273.74deg, rgba(4, 137, 163, 0.24) 3.07%, rgba(116, 209, 227, 0.24) 96.93%)",
        backgroundSelected: "#052435",
        borderSelected: "#104452",
      },
      datepicker: {
        background: "#052435",
        border: "#104452",
        week: {
          text: "#9BA2BA",
        },
        day: {
          textHover: "#E5E5E5",
        },
        range: {
          backgroundSelected: "rgba(3, 116, 138, 0.16)",
          backgroundSelectedStartEnd: "rgba(3, 116, 138, 0.16)",
        },
      },
      link: {
        hover: "#038FAD",
        active: "#02738C",
      },
      volumePriceChart: {
        horizontalDivider:
          "linear-gradient(90deg, rgba(42, 83, 105, 0) 0%, rgba(42, 83, 105, 0.67) 17.71%, rgba(42, 83, 105, 0.67) 43.23%, rgba(42, 83, 105, 0.67) 80.73%, rgba(42, 83, 105, 0) 100%)",
        verticalDivider:
          "linear-gradient(180deg, rgba(42, 83, 105, 0) 0%, rgba(42, 83, 105, 0.67) 24.79%, rgba(42, 83, 105, 0.67) 48.3%, rgba(42, 83, 105, 0.67) 72.19%, rgba(42, 83, 105, 0) 100%)",
      },
      exchangeLiquidityChart: {
        background:
          "linear-gradient(89.97deg, #052435 0.04%, rgba(5, 36, 53, 0) 20.63%, rgba(5, 36, 53, 0) 78.66%, #052435 99.98%)",
      },
      markets: {
        backgroundExtended: "#052435",
        stickyHeader: {
          backgorundMobile:
            "linear-gradient(180deg, #052435 67.35%, rgba(5, 36, 53, 0.43) 154.69%)",
          backgorundDesktop:
            "linear-gradient(180deg, #052435 51.03%, rgba(5, 36, 53, 0.43) 117.19%)",
        },
        prevNextMarketBtn: {
          background:
            "linear-gradient(273.74deg, rgba(3, 116, 138, 0.16) 3.07%, rgba(99, 179, 194, 0.16) 96.93%)",
          border: "#104452",
          shadow: "none",
        },
      },
      viewInTerminalBtn: {
        shadow: "none",
        border: "#104452",
        background: "#031925",
      },
      legendBtn: {
        shadow: "none",
        border: "#104452",
        borderSelected: "#104452",
        background: "#031925",
        backgroundHover: "#02151F",
        backgroundSelected: "#044A62",
        backgroundSelectedHover: "#053848",
      },
      scrollbar: {
        app: {
          track: "#021017",
          thumb: "#052435",
        },
        list: {
          track: "transparent",
          thumb: "#03A9CE",
        },
      },
      portfolio: {
        coinsOverviewTableRow: {
          background:
            "linear-gradient(273.74deg, rgba(3, 116, 138, 0.08) 3.07%, rgba(99, 179, 194, 0.08) 96.93%)",
          border: "rgba(255, 255, 255, 0.1)",
          shadow: "none",
        },
        colorsMap: ["#FFFFFF", "#627EEA", "#E50275", "#F3F71A", "#4E9DC9"],
      },
      ...globalColors,
    },
    typography,
    ...props,
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Source Sans Pro",
              fontStyle: "normal",
              fontDisplay: "swap",
            },
          ],
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: "none",
        },
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
        styleOverrides: {
          root: {
            "&:hover": {
              color: linkHover,
              span: {
                color: "inherit",
              },
            },
            "&:active": {
              color: linkActive,
            },
          },
        },
      },
    },
  }),
);
