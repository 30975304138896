import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import typography from "./typography";
import props from "./props";
import globalColors from "./global-colors";

const linkHover = "#2579A8";
const linkActive = "#11618F";

export default responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      mode: "light",
      primary: {
        main: "#3E92C2",
        hover: "#025688",
        selected: "#004673",
        disabled: "#707070",
      },
      secondary: {
        main: "#D5E5EF",
        hover: "#C1D1DB",
        selected: "#B6C6D0",
        property: "backgroundColor",
      },
      error: {
        main: "#F04755",
      },
      success: {
        main: "#37AF6B",
      },
      info: {
        main: "#C1D1DB",
      },
      warning: {
        main: "#ED6C02",
      },
      errorSnackbar: {
        background: "rgba(255, 99, 112, 0.1)",
        border: "#FF6370",
      },
      successSnackbar: {
        background: "rgba(110, 204, 151, 0.1)",
        border: "#6ECC97",
      },
      infoSnackbar: {
        background: "rgba(193, 209, 219, 0.1)",
        border: "#C1D1DB",
      },
      warningSnackbar: {
        background: "rgba(237, 108, 2, 0.1)",
        border: "#ED6C02",
      },
      text: {
        primary: "#052435",
        secondary: "#749AAF",
        disabled: "#707070",
      },
      background: {
        paper: "#fff",
        default: "#FAFFFF",
        main:
          "linear-gradient(90.65deg, rgba(255, 255, 255, 0) 0.48%, rgba(255, 255, 255, 0.5) 25.21%, rgba(255, 255, 255, 0.5) 66.92%, rgba(255, 255, 255, 0.5) 85.47%, rgba(255, 255, 255, 0) 99.37%), #F0FAFF",
        secondBackground: "#FCFDFE",
        footer: "#FAFAFA",
      },
      input: {
        main: {
          background: "#fff",
          border: "#F1F1F1",
          color: "#000000",
        },
        hover: {
          background: "#fff",
          border: "#EBEBEB",
        },
        selected: {
          background: "#fff",
          border: "#136799",
        },
        disabled: {
          background: "#F1F1F1",
          border: "#F1F1F1",
          color: "#707070",
        },
      },
      divider: "rgba(0, 0, 0, 0.05)",
      backgroundLines: "#136799",
      contentBackground: "#fff",
      modal: {
        backdrop: "rgba(3, 25, 37, 0.5)",
        background: "#F2F8FA",
        iconBackground:
          "linear-gradient(98.09deg, #01F3F7 -4.98%, #0077CD 98.56%)",
        icon: "#052435",
        border: "#F2F8FA",
      },
      progress: {
        background: "#DAD9DD",
        color: "#01F3F7",
      },
      tabs: {
        background: "#EDEDEF",
        selectedBackground: "#DAD9DD",
        divider: "#E2E2E2",
      },
      tab: {
        border: "rgba(5, 36, 53, 0.3)",
        borderSelected: "#3E92C2",
        borderSelectedHover: "#2579A8",
        text: "#052435",
        textHover: "#062A3D",
        textSelected: "#3E92C2",
        textSelectedHover: "#2579A8",
      },
      slider: {
        left: "#FFFFFF",
        right: "#136799",
        bar:
          "linear-gradient(270deg, #DEEBF2 3.06%, rgba(222, 235, 242, 0) 97.9%)",
        leftTrack: "#FFFFFF",
        rightTrack: "#136799",
        thumb: {
          color: "#fff",
          border: "#749AAF",
          colorValue: "#fff",
          borderValue: "#3E92C2",
        },
        track:
          "linear-gradient(270deg, #3E92C2 1.74%, rgba(62, 146, 194, 0.29) 39.91%, rgba(62, 146, 194, 0.09) 66.57%, rgba(62, 146, 194, 0) 96.3%)",
      },
      menu: {
        hover: "#F9F9F9",
      },
      footer: {
        background: "#FDF4E6",
        text: "#052435",
        link: "#052435",
        logo: "#fff",
        stopOpacity: [0.17, 0.21, 1],
        stopOpacityMobile: [0, 0.13, 1],
        gradient: "#136799",
      },
      header: {
        background:
          "linear-gradient(90.51deg, rgba(244, 248, 252, 0) 0%, #F4F8FC 50.44%)",
        secondBackground: "#fff",
        divider: "rgba(255, 255, 255, 0.15)",
      },
      mobileMenu: {
        selected: "#0D7AFC",
        background: "#F5F5F5",
      },
      avatarBackground: "#D5E5EF",
      icon: {
        primary: "#136799",
        secondary: "#D5E5EF",
      },
      switch: {
        track: "rgba(252, 150, 1, 0.35)",
        thumb: "#F5F5F5",
        border: "#E2E2E2",
        thumbChecked: "#136799",
        thumbBorder: "#EDEDED",
        thumbBorderChecked: "#075B8D",
      },
      rowBackground: {
        main: "#FAFAFA",
        hover: "#F6F6F6",
        active: "#F3F3F3",
      },
      rowAlmostBackground: {
        main: "#F7F7F8",
        hover: "#F6F6F6",
        active: "#F3F3F3",
      },
      dropdown: {
        selectedBg: "#D5E5EF",
        itemBg: "#FFF",
        border: "#F5F5F5",
      },
      chart: {
        grid: "rgba(180, 210, 227, 1)",
        gridTransparent: "rgba(180, 210, 227, 0)",
        volumeBorder: "#749AAF",
        volumeFill: "#BEDCFF",
      },
      headerMenu: {
        icon: "#ECECEC",
        iconHover: "#ECECEC",
        shadow: "0px 0px 8px rgba(51, 51, 51, 0.5)",
        shadowHover: "0px 0px 8px rgba(19, 103, 153, 0.5)",
      },
      lab: {
        border: "#F1F1F1",
      },
      table: {
        background: "#fff",
        border: "#fff",
        extendedRow: "#F7FCFF",
        extended: "#F2F8FA",
        rowShadow: "0px 4px 12px rgba(11, 21, 28, 0.04)",
      },
      picker: {
        background: "linear-gradient(273.74deg, #3E92C2 3.07%, #31759C 96.93%)",
        border: "#347BA3",
        text: "#052435",
        backgroundHover:
          "linear-gradient(273.74deg, rgba(37, 121, 168, 0.24) 3.07%, rgba(27, 93, 130, 0.24) 96.93%)",
        backgroundSelectedHover:
          "linear-gradient(273.74deg, #2579A8 3.07%, #1B5D82 96.93%)",
      },
      dataHeader: {
        icon: "#052435",
        background:
          "linear-gradient(273.74deg, rgba(62, 146, 194, 0.16) 3.07%, rgba(49, 117, 156, 0.16) 96.93%)",
        text: "#32779F",
      },
      overviewPage: {
        backgroundInterval: "rgba(255, 255, 255, 0.08)",
        backgroundIntervalBorder: "#1D7E98",
      },
      filter: {
        background: "#F7FCFF",
        textFieldBackground: "#FFFFFF",
      },
      terminal: {
        background: "#F7FCFF",
        border: "#D5DFE5",
      },
      arbitrage: {
        itemBackground:
          "linear-gradient(273.74deg, rgba(3, 116, 138, 0.16) 3.07%, rgba(99, 179, 194, 0.16) 96.93%)",
        itemBorder: "#17536C",
      },
      viewAllButton: {
        background: "rgba(255, 255, 255, 0.6)",
        shadow: "0px 16px 32px rgba(22, 100, 117, 0.08)",
        text: "#32779F",
      },
      extendedItem: {
        background: "#FFFFFF",
      },
      badge: {
        background: "#fff",
        border: "#fff",
        text: "#3E92C2",
        shadow: "0px 4px 8px rgba(30, 70, 93, 0.08)",
      },
      badgeCount: {
        background: "rgba(236, 244, 249, 0.24)",
        border: "rgba(62, 146, 194, 1)",
        shadow: "0px 4px 8px rgba(30, 70, 93, 0.08)",
      },
      paper: {
        background: "#fff",
        border: "#fff",
        shadow: "0px 16px 32px rgba(30, 70, 93, 0.06)",
        divider:
          "linear-gradient(90deg, rgba(180, 210, 227, 0) 0%, rgba(180, 210, 227, 0.67) 24.79%, rgba(180, 210, 227, 0.67) 48.3%, rgba(180, 210, 227, 0.67) 72.19%, rgba(180, 210, 227, 0) 100%)",
      },
      chartTooltip: {
        background: "rgba(255, 255, 255, 0.42)",
        border: "none",
        shadow: "0px 24px 48px rgba(30, 70, 93, 0.12)",
        divider:
          "linear-gradient(90deg, rgba(217, 234, 243, 0) 0%, #D9EAF3 46.73%, rgba(217, 234, 243, 0) 100%)",
      },
      navItem: {
        backgroundHover:
          "linear-gradient(273.74deg, rgba(62, 146, 194, 0.16) 3.07%, rgba(49, 117, 156, 0.16) 96.93%)",
        backgroundSelected:
          "linear-gradient(273.74deg, #3E92C2 3.07%, #31759C 96.93%)",
        backgroundSelectedHover:
          "linear-gradient(273.74deg, #2579A8 3.07%, #1B5D82 96.93%)",
      },
      button: {
        primary: {
          background:
            "linear-gradient(273.74deg, #3E92C2 3.07%, #31759C 96.93%)",
          backgroundHover:
            "linear-gradient(273.74deg, #2579A8 3.07%, #1B5D82 96.93%)",
          border: "#32779F",
          text: "#fff",
        },
        secondary: {
          background: "#ECF4F9",
          backgroundHover: "#DEEBF2",
          border: "#32779F",
          text: "#32779F",
        },
        outlined: {
          background: "#fff",
          backgroundHover: "#ECF4F9",
          border: "#32779F",
          text: "#32779F",
        },
      },
      stickyHeader: {
        background:
          "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.92) 63.54%, rgba(255, 255, 255, 0.42) 100%)",
      },
      pagination: {
        backgroundHover: "#F2F8FA",
        backgroundSelected: "#ECF4F9",
        borderSelected: "#B4D2E3",
      },
      datepicker: {
        background: "fff",
        border: "#fff",
        week: {
          text: "rgba(116, 154, 175, 0.5)",
        },
        day: {
          textHover: "#062A3D",
        },
        range: {
          backgroundSelected: "#ECF4F9",
          backgroundSelectedStartEnd:
            "linear-gradient(273.74deg, rgba(62, 146, 194, 0.16) 3.07%, rgba(49, 117, 156, 0.16) 96.93%)",
        },
      },
      link: {
        hover: linkHover,
        active: linkActive,
      },
      volumePriceChart: {
        horizontalDivider:
          "linear-gradient(90deg, rgba(180, 210, 227, 0) 0%, rgba(180, 210, 227, 0.67) 24.79%, rgba(180, 210, 227, 0.67) 48.3%, rgba(180, 210, 227, 0.67) 72.19%, rgba(180, 210, 227, 0) 100%)",
        verticalDivider:
          "linear-gradient(180deg, rgba(180, 210, 227, 0) 0%, rgba(180, 210, 227, 0.67) 24.79%, rgba(180, 210, 227, 0.67) 48.3%, rgba(180, 210, 227, 0.67) 72.19%, rgba(180, 210, 227, 0) 100%)",
      },
      exchangeLiquidityChart: {
        background:
          "linear-gradient(89.97deg, #FFFFFF 0.04%, rgba(255, 255, 255, 0) 20.63%, rgba(255, 255, 255, 0) 78.66%, #FFFFFF 99.98%)",
      },
      markets: {
        backgroundExtended: "#F2F8FA",
        stickyHeader: {
          backgorundMobile:
            "linear-gradient(180deg, #FFFFFF 43.54%, rgba(255, 255, 255, 0.92) 76.96%, rgba(255, 255, 255, 0.43) 100%)",
          backgorundDesktop:
            "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.92) 63.54%, rgba(255, 255, 255, 0.42) 100%)",
        },
        prevNextMarketBtn: {
          background: "#fff",
          border: "#fff",
          shadpw: "box-shadow: 0px 16px 32px rgba(30, 70, 93, 0.06)",
        },
      },
      viewInTerminalBtn: {
        shadow: "0px 16px 32px rgba(22, 100, 117, 0.08)",
        border: "",
        background: "#fff",
      },
      legendBtn: {
        shadow: "0px 8px 16px rgba(30, 70, 93, 0.08)",
        border: undefined,
        borderSelected: undefined,
        background: "#fff",
        backgroundHover: "#F2F8FA",
        backgroundSelected: "rgba(62, 146, 194, 0.1)",
        backgroundSelectedHover:
          "linear-gradient(273.74deg, rgba(37, 121, 168, 0.24) 3.07%, rgba(27, 93, 130, 0.24) 96.93%)",
      },
      scrollbar: {
        app: {
          track: "transparent",
          thumb: "#B4D2E3",
        },
        list: {
          track: "transparent",
          thumb: "#B4D2E3",
        },
      },
      portfolio: {
        coinsOverviewTableRow: {
          background: "#fff",
          border: "rgba(255, 255, 255, 0.1)",
          shadow: "0px 16px 32px rgba(30, 70, 93, 0.06)",
        },
        colorsMap: ["#052435", "#627EEA", "#E50275", "#F3F71A", "#4E9DC9"],
      },
      ...globalColors,
    },
    typography,
    ...props,
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Source Sans Pro",
              fontStyle: "normal",
              fontDisplay: "swap",
            },
          ],
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.06)",
        },
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
        styleOverrides: {
          root: {
            "&:hover": {
              color: linkHover,
              span: {
                color: "inherit",
              },
            },
            "&:active": {
              color: linkActive,
            },
          },
        },
      },
    },
  }),
);
