import { createMakeStyles } from "tss-react";
import { useTheme } from "@material-ui/core";
// import { useAppTheme } from "@components/app";
import createCache from "tss-react/@emotion/cache";

const { makeStyles } = createMakeStyles({ useTheme });

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

export default function (args) {
  const useStyles = makeStyles()(args);

  const useStylesWrapper = (props) => {
    const { classes } = useStyles(props);
    return classes;
  };

  return useStylesWrapper;
}

// export default function(args) {
//   const { makeStyles } = createMakeStyles({ useTheme });
//   return makeStyles()(args);
//   // const useStyles = makeStyles()(args);
//   //
//   // const useStylesWrapper = (props) => {
//   //   const { classes } = useStyles(props);
//   //   return classes;
//   // };
//   //
//   // return useStylesWrapper;
// };
